import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import ScrollToTop from '../hooks/ScrollToTop';

// Styled components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 40px 0px;
`;

const Heading = styled.h1`
  font-size: 36px;
  font-weight: 500;
  margin: 20px 0;
  color: #333;
`;

const GalleryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px;
  width: 90%;
  margin: 20px 0px 100px 0;
  padding: 20px;
`;

const GalleryFolder = styled.div`
  position: relative;
  width: 250px;
  height: 250px;
  cursor: pointer;
  text-align: center;
  transition: transform 0.3s ease;
  
  &:hover {
    transform: scale(1.05);
  }
`;

const StackedImages = styled.div`
  position: relative;
  width: 80%;
  height: 80%;
  border-radius: 10px;
`;

const ImageStack = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  background: url(${(props) => props.src}) no-repeat center center;
  background-size: cover;
  border-radius: 15px;
  transform: translateX(-50%) rotate(${(props) => props.rotation}deg);
  z-index: ${(props) => props.zIndex};
  filter: ${(props) => (props.zIndex === 3 ? "none" : "brightness(70%)")};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const FolderName = styled.div`
  font-size: 18px;
  font-weight: 400;
  margin-top: 30px;
  color: #333;
`;

function Gallery() {
  ScrollToTop();
  const navigate = useNavigate();

  const [galleries, setGalleries] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchGalleries = async () => {
      try {
        const response = await fetch("https://bl-ljv9.onrender.com/api/gallery");
        if (!response.ok) throw new Error("Failed to fetch galleries");
        const data = await response.json();
        setGalleries(data);
      } catch (error) {
        console.error("Error fetching galleries:", error);
        setError(error.message);
      }
    };

    fetchGalleries();
  }, []);

  const handleGalleryClick = (galleryId) => {
    navigate(`/gallery/${galleryId}`);
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <Container>
      <Heading>Gallery</Heading>
      <GalleryContainer>
        {galleries.length === 0 ? (
          <div>Loading galleries...</div>
        ) : (
          galleries.map((gallery) => (
            <GalleryFolder
              key={gallery._id}
              onClick={() => handleGalleryClick(gallery._id)}
            >
              <StackedImages>
                {gallery.imagesUrl.slice(0, 2).map((image, index) => {
                  const rotation = index === 0 ? 0 : index === 1 ? -10 : 10; // Adjust for more images
                  return (
                    <ImageStack
                      key={image}  // Use image URL as the key to ensure uniqueness
                      src={image}
                      rotation={rotation}  // Apply rotation for stacking effect
                      zIndex={3 - index}   // zIndex ensures top image stays at the top
                    />
                  );
                })}
              </StackedImages>
              <FolderName>{gallery.name}</FolderName>
            </GalleryFolder>
          ))
        )}
      </GalleryContainer>
    </Container>
  );
}

export default Gallery;
