import styled, {keyframes} from "styled-components";

const Container = styled.div`
    width: 100%;
    line-height: 35px;
    background-color: red;
    color: white;
    white-space: nowrap;
    overflow: hidden;
    box-sizing: border-box;
`;

const marquee = keyframes`
  0%   { transform: translate(0, 0); }
  100% { transform: translate(-200%, 0); }
`;

const Marquee = styled.div`
    display: flex;
    padding-left: 100%;
    width: 100%;
    animation: ${marquee} 50s linear infinite;

    div {
        padding: 0 8rem;
    }
`;




function RedBanner() {
  return (
    <Container>
      <Marquee>
        <div>We are open for new Admissions. To know more you can contact us or visit our office. Thank you!</div>

        <div>We are open for new Admissions. To know more you can contact us or visit our office. Thank you!</div>
      </Marquee>
    </Container>
  )
}

export default RedBanner