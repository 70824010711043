import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: #f9f9f9;
`;

const GalleryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 20px auto;
`;

const GalleryList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  width: 100%;
`;

const GalleryItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  font-size: 16px;
  height: 100px; /* Adjust height as needed for square aspect ratio */
  overflow: hidden;
  position: relative;
  cursor: pointer;
  
  &:hover {
    background-color: #f0f0f0;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  width: 100%;
  max-width: 600px;
`;

const Input = styled.input`
  padding: 10px;
  margin-bottom: 10px;
  width: 100%;
  max-width: 400px;
  border: 1px solid #ddd;
  border-radius: 5px;
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: #0056b3;
  }
`;

const Galleries = () => {
  const [galleries, setGalleries] = useState([]);
  const [newGalleryName, setNewGalleryName] = useState('');
  const navigate = useNavigate(); // Use navigate hook for navigation

  useEffect(() => {
    const fetchGalleries = async () => {
      try {
        const response = await fetch('https://bl-ljv9.onrender.com/api/gallery');
        const data = await response.json();
        setGalleries(data);
      } catch (error) {
        console.error('Error fetching galleries:', error);
      }
    };

    fetchGalleries();
  }, []);

  const handleCreateGallery = async (e) => {
    e.preventDefault();

    if (!newGalleryName) {
      alert('Please enter a gallery name.');
      return;
    }

    try {
      const response = await fetch('https://bl-ljv9.onrender.com/api/gallery/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name: newGalleryName }),
      });

      const result = await response.json();
      if (response.ok) {
        setGalleries((prevGalleries) => [...prevGalleries, result]);
        setNewGalleryName('');
      } else {
        alert('Failed to create gallery.');
      }
    } catch (error) {
      console.error('Error creating gallery:', error);
    }
  };

  const handleGalleryClick = (id) => {
    navigate(`/admin/image-upload/${id}`);
  };

  return (
    <Container>
      <GalleryWrapper>
        <GalleryList>
          {galleries.map((gallery) => (
            <GalleryItem
              key={gallery._id}
              onClick={() => handleGalleryClick(gallery._id)}
            >
              {gallery.name}
            </GalleryItem>
          ))}
        </GalleryList>
        <Form onSubmit={handleCreateGallery}>
          <Input
            type="text"
            value={newGalleryName}
            onChange={(e) => setNewGalleryName(e.target.value)}
            placeholder="Enter new gallery name"
          />
          <Button type="submit">Create Gallery</Button>
        </Form>
      </GalleryWrapper>
    </Container>
  );
};

export default Galleries;
