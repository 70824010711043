import React, { useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: #f9f9f9;
`;

const ImageUploaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  max-width: 600px;
  text-align: center;
`;

const Note = styled.p`
  color: #555;
  font-size: 14px;
  margin-bottom: 10px;
`;

const Input = styled.input`
  display: none;
`;

const Label = styled.label`
  display: block;
  width: 100%;
  height: 60px;
  background-color: #f0f0f0;
  border: 2px dashed #ccc;
  border-radius: 10px;
  text-align: center;
  line-height: 60px;
  cursor: pointer;
  color: #333;
  font-size: 16px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e0e0e0;
  }
`;

const DropArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  border: 2px dashed #ccc;
  border-radius: 10px;
  background-color: #fafafa;
  color: #888;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const ImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
  justify-content: center;
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 150px;
  height: 150px;
  overflow: hidden;
  border: 1px solid #ddd;
  border-radius: 10px;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const RemoveButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  
  &:hover {
    background-color: rgba(255, 0, 0, 0.8);
  }
`;

const UploadButton = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const Loader = styled.div`
  margin-top: 20px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #007bff;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const ImageUploader = () => {
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // Add loading state
  const { id } = useParams();

  const handleImageUpload = (files) => {
    const validFiles = Array.from(files).filter(file => {
      if (file.size > 5 * 1024 * 1024) {
        alert(`File ${file.name} is larger than 5MB and will be ignored.`);
        return false;
      }
      return true;
    });

    if (validFiles.length + images.length > 10) {
      alert('You can only upload a maximum of 10 images.');
      return;
    }

    setImages((prevImages) => [...prevImages, ...validFiles]);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    handleImageUpload(e.dataTransfer.files);
  };

  const removeImage = (indexToRemove) => {
    setImages((prevImages) =>
      prevImages.filter((_, index) => index !== indexToRemove)
    );
  };

  const handleUpload = async () => {
    const formData = new FormData();
    images.forEach((image, index) => {
      formData.append(`images`, image);
    });

    setIsLoading(true); // Set loading to true

    try {
      const response = await fetch(`https://bl-ljv9.onrender.com/api/gallery/upload/${id}`, {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        alert('Images uploaded successfully!');
        setImages([]); // Clear images after upload
      } else {
        alert('Failed to upload images.');
      }
    } catch (error) {
      console.error('Error uploading images:', error);
      alert('An error occurred while uploading images.');
    } finally {
      setIsLoading(false); // Set loading to false
    }
  };

  return (
    <Container>
      <ImageUploaderWrapper>
        <Note>Images should be less than 5MB each. Maximum of 10 images.</Note>
        <Label htmlFor="file-upload">Click to upload images or drag and drop them below</Label>
        <Input
          id="file-upload"
          type="file"
          accept="image/*"
          multiple
          onChange={(e) => handleImageUpload(e.target.files)}
        />
        <DropArea
          onDragOver={(e) => e.preventDefault()}
          onDrop={handleDrop}
        >
          Drag & Drop Images Here
        </DropArea>
        <ImagesContainer>
          {images.map((image, index) => (
            <ImageWrapper key={index}>
              <Image
                src={URL.createObjectURL(image)}
                alt={`upload-${index}`}
              />
              <RemoveButton onClick={() => removeImage(index)}>×</RemoveButton>
            </ImageWrapper>
          ))}
        </ImagesContainer>
        {isLoading ? (
          <Loader /> 
        ) : (
          <UploadButton onClick={handleUpload} disabled={images.length === 0}>
            Upload Images
          </UploadButton>
        )}
      </ImageUploaderWrapper>
    </Container>
  );
};

export default ImageUploader;
