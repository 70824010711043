import styled from 'styled-components'
import PageBanner from '../components/PageBanner';
import ScrollToTop from '../hooks/ScrollToTop';

//images
import BACK from "../images/facility-bcak.jpeg";
import CONTACT from "../images/Contact-back.png"

//icons
import CallIcon from "../icons/Call.svg"
import EmailIcon from "../icons/Mail.svg"
import LocationIcon from "../icons/Location.svg"
import ClockIcon from "../icons/Clock.svg"


const Container = styled.div`
  width: 100vw;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Main = styled.div`
  width: 100%;
  height: calc(100vh - 200px);
  margin-bottom: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Title = styled.h1`
  font-size: 2rem;
  color: #333;
  margin: 20px;
  padding: 20px;
`

const Card = styled.div`
  width: 80%;
  height: 100%;
  margin: 20px;
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid #8d8d8d;
  border-radius: 10px;
  box-shadow: 1px 1px 10px 0px #9a9a9a;
  `

const Part = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
  width: 100%;

  //breakpoint of mobile
  @media (max-width: 480px) {
    display: ${(props) => props.display};
  }
`

const DescriptionContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;

  //breakpoint of mobile
  @media (max-width: 480px) {
    width: 90%;
  }
`

const Icon = styled.img`
  width: 20px;
  height: 20px;
  object-fit: cover;

  //breakpoint of mobile
  @media (max-width: 480px) {
    width: 15px;
    height: 15px;
  }
`

const Description = styled.p`
  margin: 0px;
  width: 340px;
  font-size: 16px;
  font-weight: 300;
  text-align: center;

  //breakpoint of mobile
  @media (max-width: 480px) {
    width: 90%;
    font-size: 14px;
  }
`

const ImageContainer = styled.div`
  width: 100%;
  height:100%;
  display: flex;
  align-items: center;
  justify-content: center;

  //breakpoint of mobile
  @media (max-width: 480px) {
    display: none;
  }
`

const Image = styled.img`
  width: 90%;
  height: 90%;
  object-fit: cover;
`  


function Contact() {
  ScrollToTop();
  return (
    <Container>
      <PageBanner img={BACK} pagename={"Contact Us"} />
      <Main>
        <Title>Contact Us</Title>
        <Card>
          <Part>
            <DescriptionContainer>
              <Icon src={CallIcon} alt="Call" />
              <Description>9650674928 || 8448557569 || 9818609552</Description>
            </DescriptionContainer>

            <DescriptionContainer>
              <Icon src={EmailIcon} alt="Email" />
              <Description>newbloomingbudsschool4@gmail.com</Description>
            </DescriptionContainer>

            <DescriptionContainer>
              <Icon src={LocationIcon} alt="Location" />
              <Description>RZ-108A,Street no.3,Raghu Nagar,Shiv block,Pankha road,New Delhi-45</Description>
            </DescriptionContainer>

            <DescriptionContainer>
              <Icon src={ClockIcon} alt="Clock" />
              <Description>
                Mon - Fri(8:30am - 1:30pm) Summer,<br/>
                Mon - Fri(9:00am - 2:00pm) Winter
              </Description>
            </DescriptionContainer>
          </Part>
          <Part display="none">
            <ImageContainer>
              <Image src={CONTACT} alt="Contact" />
            </ImageContainer>
          </Part>
        </Card>


      </Main>
    </Container>
  )
}

export default Contact